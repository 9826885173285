<template>
  <div class="bill">
    <div class="top">
      <span style="margin-right: 5px"
        >{{ lang_data == 1 ? "编号：" : "No.："
        }}<el-input
          v-model="order_no"
          :placeholder="lang_data == 1 ? '请输入编号' : 'Please Enter'"
          size="small"
          style="width: 160px; margin-bottom: 5px"
          clearable
        ></el-input
      ></span>
      <span style="margin-right: 5px"
        >{{ lang_data == 1 ? "作废状态：" : "Delete Status："
        }}<el-select
          v-model="invalid_status"
          :placeholder="lang_data == 1 ? '请选择' : 'Please choose'"
          size="small"
          style="width: 160px"
          clearable
        >
          <el-option :label="lang_data == 1 ? '有效' : 'Effective'" value="1">
          </el-option>
          <el-option
            :label="lang_data == 1 ? '已作废' : 'The cancellation'"
            value="2"
          >
          </el-option>
        </el-select>
      </span>
      <span style="margin-right: 5px"
        >{{ lang_data == 1 ? "状态：" : "Status："
        }}<el-select
          v-model="receivable_status"
          :placeholder="lang_data == 1 ? '请选择' : 'Please choose'"
          size="small"
          style="width: 160px"
          clearable
        >
          <el-option
            :label="lang_data == 1 ? '待回款' : 'For the receivable'"
            value="1"
          >
          </el-option>
          <el-option
            :label="lang_data == 1 ? '已回款' : 'Has the receivable'"
            value="2"
          >
          </el-option>
          <el-option
            :label="lang_data == 1 ? '已作废' : 'The cancellation'"
            value="3"
          >
          </el-option>
        </el-select>
      </span>
      <template v-if="lang_data != 1"><br /></template>
      <span
        >{{ lang_data == 1 ? "核销状态：" : "Write-off status："
        }}<el-select
          v-model="status"
          :placeholder="lang_data == 1 ? '请选择' : 'Please choose'"
          size="small"
          style="width: 160px"
          clearable
        >
          <el-option
            :label="lang_data == 1 ? '待核销' : 'To cancel after verification'"
            value="1"
          >
          </el-option>
          <el-option
            :label="
              lang_data == 1 ? '核销中' : 'In the cancel after verification'
            "
            value="2"
          >
          </el-option>
          <el-option
            :label="
              lang_data == 1 ? '已核销' : 'Have cancel after verification'
            "
            value="3"
          >
          </el-option>
        </el-select>
      </span>

      <span>
        <el-button
          type="primary"
          size="small"
          class="ml10"
          @click="getList()"
          >{{ lang_data == 1 ? "查询" : "Query" }}</el-button
        >
      </span>
    </div>
    <div class="table">
      <el-table
        :data="tableData"
        style="width: 100%"
        v-loading="loading"
        :element-loading-text="lang_data == 1 ? '拼命加载中' : 'Loading'"
        border
      >
        <el-table-column
          prop="order_no"
          :label="lang_data == 1 ? '结算单编号' : 'Billing No.'"
          width="170"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="status.label"
          :label="lang_data == 1 ? '核销状态' : 'Write-off status'"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="invalid_status.label"
          :label="lang_data == 1 ? '状态' : 'Status'"
          width="70"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="receivable_price"
          :label="lang_data == 1 ? '应回款金额' : 'Receivable'"
          width="100"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="discounts_price"
          :label="lang_data == 1 ? '优惠金额' : 'Discount'"
          width="100"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="reality_receivable_price"
          :label="lang_data == 1 ? '实际应回款金额' : 'Actual paid'"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="received_price"
          :label="lang_data == 1 ? '已回款金额' : 'Paid'"
          width="100"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="unreceived_price"
          :label="lang_data == 1 ? '未回款金额' : 'Unpaid'"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">
            {{
              (
                Number(row.unreceived_price) + Number(row.paymenting_amount)
              ).toFixed(2)
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          :label="lang_data == 1 ? '创建时间' : 'Create time'"
          width="140"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">
            {{ $moment(row.create_time * 1000).format("YYYY-MM-DD HH:MM") }}
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          :label="lang_data == 1 ? '操作' : 'Action'"
          width="140"
          fixed="right"
        >
          <template slot-scope="{ row }">
            <el-link type="primary" @click="billDetailsClick(row)" class="mr10">{{
              lang_data == 1 ? "查看" : "Check"
            }}</el-link>
            <el-link
              type="primary"
              @click="payBackClick(row)"
              v-if="
                (row.status.value != 3 &&
                  row.invalid_status.value != 2 &&
                  row.unreceived_price != 0) ||
                row.paymenting_amount != 0"
              >{{ lang_data == 1 ? "回款" : "Payment" }}</el-link
            >

          </template>
        </el-table-column>
      </el-table>
      <div
        class="page"
        style="margin: 10px 0; float: right; padding-right: 10px"
      >
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="current_page"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="page_size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <PayBack
      v-if="payBackShow"
      @cancel="cancel"
      :obj="obj"
      :payBackShow="payBackShow"
    ></PayBack>
    <BillDetails
      v-if="billDetailsShow"
      @cancel="billDetailsShow = false"
      :obj="obj"
    ></BillDetails>
  </div>
</template>
<script>
import PayBack from "./components/payBack";
import BillDetails from "./components/billDetails";
export default {
  components: {
    PayBack,
    BillDetails,
  },
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      page_size: 15,
      current_page: 1,
      order_no: "",
      status: "",
      invalid_status: "1",
      receivable_status: "",
      tableData: [],
      total: 0,
      loading: false,
      payBackShow: false,
      billDetailsShow: false,
      obj: {},
    };
  },
  created() {
    this.getList();
  },
  methods: {
    cancel(obj) {
      this.payBackShow = false;
      if (obj) {
        this.getList();
      }
    },
    payBackClick(obj) {
      this.payBackShow = true;
      this.obj = obj;
    },
    billDetailsClick(obj) {
      this.billDetailsShow = true;
      this.obj = obj;
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.current_page = val;
      this.getList();
    },
    getList() {
      this.loading = true;
      this.$axios
        .post("/ucenter/getOrderSettlementList", {
          page_size: this.page_size,
          current_page: this.current_page,
          order_no: this.order_no,
          status: this.status,
          invalid_status: this.invalid_status,
          receivable_status: this.receivable_status,
        })
        .then((res) => {
          if (res.code != 1) {
            this.$message.error(res.msg);
          } else {
            this.tableData = res.data.list;
            this.total = res.data.total_count || 0;
          }
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.bill {
  font-size: 13px;
  color: #606266;
  height: 100%;
  overflow: auto;
  .table {
    overflow: hidden;
  }
  .top {
    padding: 20px;
  }
}
</style>
<style lang="scss">
.bill {
  .el-table th,
  .el-table td {
    padding: 6px;
    font-size: 13px;
  }
}
</style>