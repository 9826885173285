<template>
  <div id="basic">
    <div v-if="info.doctor_info" class="flexdc">
      <el-form
        label-width="160px"
        label-position="right"
        :rules="Rules"
        :model="data"
        ref="ruleForm"
      >
        <el-form-item :label="lang_data == 1 ? '头像：' : 'Profile photo：'">
          <div class="userCenterImg">
            <img @error="notFind($event, 80)" :src="data.photo" />
            <UploadImg
              ref="uploadImg"
              class="ml10"
              :type="1"
              :photo="data.photo"
              @update-img="updateImg"
              @upload-img-success="uploadImgSuccess"
            >
            </UploadImg>
          </div>
        </el-form-item>
        <el-form-item
          :label="lang_data == 1 ? '姓名：' : 'Name：'"
          prop="true_name"
        >
          <el-input
            size="small"
            style="width: 150px"
            v-model="data.true_name"
          ></el-input>
        </el-form-item>
        <el-form-item :label="lang_data == 1 ? '性别：' : 'Gender：'">
          <el-radio-group v-model="data.gender">
            <el-radio :label="1">{{ lang_data == 1 ? "男" : "Boy" }}</el-radio>
            <el-radio :label="2">{{ lang_data == 1 ? "女" : "Girl" }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="lang_data == 1 ? '手机号：' : 'Phone：'">
          <span class="mr20" v-if="data.mobile">{{ data.mobile }}</span>
          <el-button size="mini" round type="primary" @click="editClick(1)">{{
            lang_data == 1 ? "修改手机号" : "Change Cellphone Number"
          }}</el-button>
        </el-form-item>
        <el-form-item :label="lang_data == 1 ? '邮箱：' : 'email：'">
          <span class="mr20" v-if="data.email">{{ data.email }}</span>
          <el-button size="mini" round type="primary" @click="editClick(2)">{{
            lang_data == 1 ? "修改邮箱" : "Modifying an Email Address"
          }}</el-button>
        </el-form-item>
        <el-form-item
          :label="lang_data == 1 ? '剩余预付款：' : 'Remaining Credit：'"
          v-if="isAdmin"
        >
          {{ info.doctor_info.advance_price
          }}<el-button
            size="mini"
            round
            type="primary"
            class="ml20"
            @click="rechargePrepaymentShow = true"
            >{{
              lang_data == 1 ? "充值" : "add credit to the pre-paid account"
            }}</el-button
          >
        </el-form-item>
        <el-form-item
          :label="lang_data == 1 ? '牙位显示类型：' : 'Dental display：'"
        >
          <el-radio-group v-model="data.tooth_show_type">
            <!-- <el-radio :label="1">数字标记法（FDI）</el-radio> -->
            <el-radio :label="2">{{
              lang_data == 1 ? "部位记录法" : "Palmer"
            }}</el-radio>
            <el-radio :label="3">{{
              lang_data == 1 ? "通用记录法" : "Universal"
            }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="lang_data == 1 ? '所属诊所：' : 'Clinic Name：'">
          {{ info.clinic_info.name }}
        </el-form-item>
        <el-form-item
          :label="lang_data == 1 ? '诊所收货地址：' : 'Clinic Address：'"
        >
          <template v-if="info.clinic_info.recipient_address.county">
            <div class="lh22">
              {{ info.clinic_info.recipient_address.accept_name }}
              {{ info.clinic_info.recipient_address.tel }}<br />
              {{ info.clinic_info.recipient_address.province.value }}
              {{ info.clinic_info.recipient_address.city.value }}
              {{ info.clinic_info.recipient_address.county.value }}
              {{ info.clinic_info.recipient_address.addr }}
            </div>
          </template>
        </el-form-item>

        <el-form-item :label="lang_data == 1 ? '个人地址：' : 'Personal address：'">
          <CitySelect
            ref="citySelect"
            v-if="data && data.province"
            @city-select-change="citySelectChange"
            :county1="data.county"
            :city1="data.city"
            :province1="data.province"
            class="flex-1"
          ></CitySelect>
          <el-input
            size="small"
            v-model="data.addr"
            :placeholder="lang_data == 1 ? '请输入详细地址' : 'Please Enter'"
          ></el-input>
        </el-form-item>
        <el-form-item :label="lang_data == 1 ? '个人简介：' : 'Personal profile：'">
          <el-input
            type="textarea"
            maxlength="200"
            v-model="data.intro"
            show-word-limit
            :rows="4"
          ></el-input>
        </el-form-item>
        <el-form-item class="mt20">
          <el-button type="primary" round @click="submitForm('ruleForm')">
            {{ lang_data == 1 ? "保存提交" : "Save Submit" }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <Moblie
      v-if="moblieStatus"
      :moblieStatus="moblieStatus"
      @cancel="moblieStatus = false"
      :mobile="info.doctor_info.mobile"
    ></Moblie>
    <EmailEdit
      v-if="emailStatus"
      :emailStatus="emailStatus"
      @cancel="emailStatus = false"
      :emailVal="info.doctor_info.email"
    ></EmailEdit>
    <RechargePrepayment
      v-if="rechargePrepaymentShow"
      :rechargePrepaymentShow="rechargePrepaymentShow"
      @cancel="cancel"
    ></RechargePrepayment>
  </div>
</template>

<script>
import UploadImg from "./components/uploadImg";
import RechargePrepayment from "./components/rechargePrepayment";
import CitySelect from "@/components/citySelect";
import Moblie from "./components/moblie.vue";
import EmailEdit from "./components/emailEdit.vue";
import { mapActions } from "vuex";
export default {
  name: "userCenterBasic",
  components: {
    UploadImg,
    CitySelect,
    Moblie,
    EmailEdit,
    RechargePrepayment,
  },
  props: ["info"],
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      loading: false,
      moblieStatus: false,
      emailStatus: false,
      rechargePrepaymentShow: false,
      picUrl: "",
      addressData: {
        provincetxt: "",
        citytxt: "",
        countytxt: "",
        province: "",
        city: "",
        county: "",
      },
      data: {
        tooth_show_type: 2,
      },
      Rules: {
        true_name: [
          {
            required: true,
            message:
              localStorage.lang_data == 1 ? "姓名不能为空" : "Can`t be empty",
            trigger: "blur",
          },
          {
            min: 2,
            max: 20,
            message:
              localStorage.lang_data == 1
                ? "请输入2-20个字符"
                : "Please enter 2-20 characters",
            trigger: "blur",
          },
        ],
      },
      pickerBeforeToday: {
        disabledDate(date) {
          return date && date.valueOf() > Date.now();
        },
      },
      certModal: {
        open: false,
      },
    };
  },
  computed: {
    isAdmin() {
      let isadmin = localStorage.is_admin > 0;
      return isadmin;
    },
  },
  created() {
    // this.getInfo();
  },
  mounted() {
    this.data = Object.assign({}, this.info.doctor_info);
    this.addressData = {
      provincetxt: this.data.province.value,
      citytxt: this.data.city.value,
      countytxt: this.data.county.value,
      province: this.data.province.key,
      city: this.data.city.key,
      county: this.data.county.key,
    };
  },
  methods: {
    editClick(val) {
      if (val == 1) {
        this.moblieStatus = true;
      } else if (val == 2) {
        this.emailStatus = true;
      }
    },
    cancel(obj) {
      if (obj) {
        // 充值成功更新数据
        this.$emit("renew");
      }
      this.rechargePrepaymentShow = false;
    },
    updateImg(src) {
      this.data.photo = src;
    },
    uploadImgSuccess(src) {
      console.log(src);
      if (src != undefined) {
        this.data.photo = src;
      }
      this.editInfo();
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //   this.editInfo();
          this.$refs.uploadImg.$emit("startUpload");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    editInfo() {
      //修改个人资料
      var params = {
        photo: this.data.photo.replace(new RegExp(this.picUrl, "g"), ""),
        true_name: this.data.true_name,
        gender: this.data.gender,
        // birthday: this.info.birthday == '' ? '0001-01-01' : moment(this.info.birthday).format('YYYY-MM-DD'),
        mobile: this.data.mobile,
        province: this.addressData.province,
        city: this.addressData.city,
        county: this.addressData.county,
        addr: this.data.addr,
        tooth_show_type: this.data.tooth_show_type,
        experience: {
          intro: this.data.intro,
        },
      };
      this.$axios.post("/ucenter/editinfo", params).then((res) => {
        if (res.code != 1) {
          this.$message.error(res.msg);
          return;
        } else {
          this.$message.success(
            this.lang_data == 1 ? "保存成功" : "Submitted successfully"
          );
          this.$emit("update");
          let usercenter = {
            true_name: params.true_name,
            photo: params.photo,
            mobile: params.mobile,
          };
          this.changeUserInfo(usercenter);
        }
      });
    },
    citySelectChange(val) {
      this.addressData[val.key + "txt"] = val.label;
      this.addressData[val.key] = val.value;
    },
    ...mapActions(["changeUserInfo"]),
  },
};
</script>

<style lang="scss" scoped="scoped">
.lh22 {
  line-height: 22px;
  margin-top: 10px;
}
.addImgBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  background-color: #f6f7f8;
  cursor: pointer;
  .icon-personal-add {
    margin: 5px auto;
    width: 24px;
    height: 24px;
    background-image: url("/static/img/icon-personal-add.svg");
  }
}
#basic {
  display: flex;
  font-size: 15px;
  padding: 50px 190px 50px 70px;
  flex-direction: column;
  .infoTab {
    position: relative;
    text-align: left;
    height: 28px;
    font-size: 18px;
    color: #333;
    line-height: 28px;
    margin-left: 10px;
    &::before {
      display: block;
      position: absolute;
      top: 6px;
      left: -10px;
      content: "";
      width: 4px;
      height: 18px;
      background-color: #0086cd;
    }
  }
}
.userCenterImg {
  display: flex;
  align-items: center;
  img {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    margin-right: 10px;
    border: 1px solid #c3c3c3;
  }
}
.el-form-item {
  margin-bottom: 10px;
}
</style>
