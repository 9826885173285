<template>
  <div id="auth_panel">
    <ul>
      <li>
        <span class="label">{{
          lang_data == 1 ? "医师执业证：" : "Dr.'s Licence："
        }}</span>
        <uploadNoCropper
          ref="uploadNoCropper"
          :type="7"
          @upload-img-success="licenseSuccess"
          @update-img="updatelicense"
        >
          <div slot="uploadBtn">
            <div v-if="info.auth.doctor_license">
              <img
                :src="getImage(info.auth.doctor_license)"
                alt=""
                style="cursor: pointer; width: 200px"
                :title="
                  lang_data == 1
                    ? '点击重新上传照片'
                    : 'Reupload photo'
                "
              />
            </div>
            <div
              v-else
              class="addImgBox"
              :title="
                lang_data == 1 ? '点击上传照片：' : 'Upload photos：'
              "
            >
              <span class="icon-personal-add"></span>
            </div>
          </div>
        </uploadNoCropper>
      </li>
      <li>
        <span class="label">{{
          lang_data == 1 ? "医师资格证：" : "Doctor's certificate："
        }}</span>
        <uploadNoCropper
          ref="uploadNoCropper"
          :type="7"
          @upload-img-success="certSuccess"
          @update-img="updateCert"
        >
          <div slot="uploadBtn">
            <div v-if="info.auth.qualification">
              <img
                :src="getImage(info.auth.qualification)"
                alt=""
                style="cursor: pointer; width: 200px"
                :title="
                  lang_data == 1
                    ? '点击重新上传照片'
                    : 'Reupload photo'
                "
              />
            </div>
            <div
              v-else
              class="addImgBox"
              :title="
                lang_data == 1 ? '点击上传照片' : 'Upload photos'
              "
            >
              <span class="icon-personal-add"></span>
            </div>
          </div>
        </uploadNoCropper>
      </li>
      <li class="remark">
        <span class="label">{{ lang_data == 1 ? "状态：" : "State：" }}</span>
        <el-tag>{{ info.auth.qualification_auth.value }}</el-tag>
        <span v-if="info.auth.qualification_auth.key == 2" class="ml20">
          <el-popover
            placement="right-start"
            trigger="hover"
            width="260"
            :content="info.auth.qualification_auth.remark"
          >
            <el-button slot="reference" type="text">{{
              lang_data == 1 ? "查看原因" : "View reason"
            }}</el-button>
          </el-popover>
        </span>
      </li>
      <li>
        <span class="label"></span>
        <span>
          <el-button type="primary" round @click="editInfo">
            {{ lang_data == 1 ? "保存提交" : "Save Submit" }}
          </el-button>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import uploadNoCropper from "./components/uploadNoCropper";
export default {
  name: "auth",
  components: {
    uploadNoCropper,
  },
  props: ["info"],
  data() {
    return {
      data: {},
      certModal: {
        open: false,
      },
      lang_data: localStorage.lang_data || 1,
    };
  },
  mounted() {},
  methods: {
    getImage(url, width = 140, height = 90) {
      if (url) {
        let aliyunUrl = "oss-cn-qingdao.aliyuncs.com";
        let idx = url.indexOf(aliyunUrl);
        if (idx != -1) {
          // 如果是阿里云图片查看缩略图
          let w = width ? `w_${width}` : "";
          let h = height ? `h_${height}` : "";
          return (
            url.split("?")[0] +
            `?x-oss-process=image/resize,m_mfit,${w + (w ? "," : "") + h}`
          );
        } else {
          // 本地图片
          return url;
        }
      } else {
        return "";
      }
    },

    updateCert(obj, file) {
      console.log(obj);
      this.$getuploadsign(7, file).then((res) => {
        let url = res.url;
        this.info.auth.qualification = url;
      });
    },
    updatelicense(obj, file) {
      console.log(obj);
      this.$getuploadsign(7, file).then((res) => {
        if (res.res.code == 1) {
          let url = res.url;
          this.info.auth.doctor_license = url;
        }
      });
    },
    certSuccess(src) {
      if (src != undefined) {
        this.info.auth.qualification = src;
      }
    },
    licenseSuccess(src) {
      if (src != undefined) {
        this.info.auth.doctor_license = src;
      }
    },

    editInfo() {
      // 修改个人资料
      var params = {
        qualification: this.info.auth.qualification,
        doctor_license: this.info.auth.doctor_license,
      };
      this.$axios.post("/ucenter/editinfo", params).then((res) => {
        if (res.code != 1) {
          this.$message.error(res.msg);
        } else {
          this.$message.success(
            this.lang_data == 1 ? "保存成功" : "Submitted successfully"
          );
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped="scoped">
#auth_panel {
  padding: 50px 70px;
  color: #333333;
  font-size: 14px;
  .addImgBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .icon-personal-add {
      margin: 5px auto;
      width: 40px;
      height: 40px;
      background-image: url("~@/assets/img/addico.png");
    }
  }
  ul {
    li {
      display: flex;
      margin-bottom: 30px;
      align-items: center;
      .label {
        width: 160px;
        text-align: right;
        display: inline-block;
      }
      &.remark {
        line-height: 40px;
      }
    }
  }
  .pointer {
    width: 200px;
  }
}
</style>
