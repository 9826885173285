<template>
  <div class="prepayments">
    <div class="top">
      <p>
        <span>{{
          lang_data == 1 ? "预付款总额：" : "Amount of your prepayment："
        }}</span
        ><b>{{ info.doctor_info.advance_total_price }}</b>
        <el-button
          size="mini"
          class="ml20"
          type="primary"
          round
          @click="rechargePrepaymentShow = true"
          >{{
            lang_data == 1 ? "充值" : "add credit to the pre-paid account"
          }}</el-button
        >
      </p>
      <p>
        <span>{{
          lang_data == 1 ? "预付款已使用金额：" : "Used of your prepayment："
        }}</span
        ><b>{{ info.doctor_info.advance_use_price }}</b>
      </p>
      <p>
        <span>{{
          lang_data == 1 ? "预付款退款金额：" : "Refund of your prepayment："
        }}</span
        ><b>{{ info.doctor_info.advance_refund_price }}</b>
      </p>
      <p>
        <span>{{
          lang_data == 1 ? "预付款剩余金额：" : "Balance of your prepayment："
        }}</span
        ><b>{{ info.doctor_info.advance_price }}</b>
      </p>
    </div>

    <div class="table">
      <el-table
        :data="tableData"
        style="width: 100%"
        v-loading="loading"
        :element-loading-text="lang_data == 1 ? '拼命加载中' : 'Loading'"
        border
      >
        <el-table-column
          prop="create_time"
          :label="lang_data == 1 ? '时间' : 'Time'"
          width="180"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">
            <span>{{
              $moment(row.create_time * 1000).format("YYYY-MM-DD HH:MM")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="type.label"
          :label="lang_data == 1 ? '变动类型' : 'Changing Type'"
          width="150"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="pay_type.label"
          :label="lang_data == 1 ? '付款方式' : 'Payment Method'"
          width="150"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="pay_no"
          :label="lang_data == 1 ? '付款单号' : 'Payment No.'"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="price"
          :label="lang_data == 1 ? '金额' : 'Amount'"
          width="150"
          show-overflow-tooltip
        >
        </el-table-column>
      </el-table>
      <div
        class="page"
        style="margin: 10px 0; float: right; padding-right: 10px"
      >
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="current_page"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="page_size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <RechargePrepayment
      v-if="rechargePrepaymentShow"
      :rechargePrepaymentShow="rechargePrepaymentShow"
      @cancel="cancel"
    ></RechargePrepayment>
  </div>
</template>

<script>
import RechargePrepayment from "./components/rechargePrepayment";
export default {
  components: {
    RechargePrepayment,
  },
  props: ["info"],
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      page_size: 15,
      current_page: 1,
      total: 0,
      tableData: [],
      rechargePrepaymentShow: false,
      loading: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    cancel(obj) {
      if (obj) {
        // 充值成功更新数据
        this.$emit("renew");
        this.getList();
      }
      this.rechargePrepaymentShow = false;
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.current_page = val;
      this.getList();
    },
    getList() {
      this.loading = true;
      this.$axios
        .post("/ucenter/getAdvancePriceList", {
          page_size: this.page_size,
          current_page: this.current_page,
        })
        .then((res) => {
          if (res.code != 1) {
            this.$message.error(res.msg);
          } else {
            this.tableData = res.data.list;
            this.total = res.data.total_count || 0;
          }
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.prepayments {
  font-size: 13px;
  height: 100%;
  overflow: auto;
  .top {
    padding: 30px 0 30px 50px;
    font-size: 15px;
    span {
      color: #606266;
      line-height: 30px;
      width: 240px;
      display: inline-block;
      text-align: right;
    }
  }
  .table {
    font-size: 13px;
    overflow: hidden;
  }
}
</style>
<style lang="scss">
.prepayments {
  .el-table th,
  .el-table td {
    padding: 6px;
    font-size: 13px;
  }
}
</style>